import React, { ReactNode, useState } from 'react'
import { useRouter } from 'hooks/useRouter'
import Image from 'next/image'
import { Trigger, Root } from '@radix-ui/react-dialog'
import { VisuallyHidden } from 'components/VisuallyHidden'
import { useTranslation } from 'hooks/useTranslation'
import { Icon } from 'components/Icon'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import { MyAccountLink, ShoppingCartLink } from 'components/Header/Header'
import { IconName } from 'components/Icon/iconNames'
import { Content, CloseButton, SectionsNav, SocialNav, SubMenu } from './MobileHeaderMenu.styles'
import { Link } from 'components/Link'
import logo from '../../../../../public/assets/company-name.svg'

export type MobileHeaderMenuProps = {
  isMyAccountMenuOpen: boolean
  setIsMyAccountMenuOpen: (isMyAccountMenuOpen: boolean) => void
  setShowLogoutConfirmationModal: (isShowLogoutConfirmationModal: boolean) => void
  isLogged: boolean
  trigger?: ReactNode
  defaultOpen?: boolean
  open?: boolean
  onOpenChange?: (isOpen: boolean) => void
}

type SocialNavType = {
  label: string
  icon: IconName
  href: string
}
export const MobileHeaderMenu: React.FC<MobileHeaderMenuProps> = ({
  isMyAccountMenuOpen,
  setIsMyAccountMenuOpen,
  setShowLogoutConfirmationModal,
  isLogged,
  open,
  trigger,
  onOpenChange,
  defaultOpen = false,
  ...props
}) => {
  const { t } = useTranslation()

  const { pathname } = useRouter()

  const [isMyAccountMobileMenuOpen, setIsMyAccountMobileMenuOpen] = useState(isMyAccountMenuOpen)

  const SECTIONS_NAV = [
    {
      label: t('header:CTA'),
      href: '/meal-plan',
    },
    {
      label: t('common:mobile-menu.wwfr'),
      href: '/why-we-feed-raw',
    },
    {
      label: t('common:mobile-menu.ourStory'),
      href: '/our-story',
    },
    {
      label: t('common:mobile-menu.products'),
      href: '/products',
    },
    {
      label: t('header:reviews'),
      href: '/reviews',
    },
  ]

  const SOCIAL_NAV: SocialNavType[] = [
    {
      label: t('common:mobile-menu.instagram'),
      icon: 'instagram',
      href: 'https://www.instagram.com/wefeedraw/',
    },
    {
      label: t('common:mobile-menu.facebook'),
      icon: 'facebook',
      href: 'https://www.facebook.com/wefeedraw/',
    },
    {
      label: t('common:mobile-menu.email'),
      icon: 'envelope',
      href: 'mailto:hello@wefeedraw.com',
    },
    {
      label: t('common:mobile-menu.phone'),
      icon: 'phone',
      href: 'tel:+18437322849',
    },
  ]

  return (
    <Root defaultOpen={defaultOpen} open={open} onOpenChange={onOpenChange}>
      <Trigger asChild>{trigger}</Trigger>
      <Content {...props}>
        <Stack direction="column" justifyContent="space-between" css={{ height: '100%' }}>
          <div>
            <Stack justifyContent="space-between">
              <Stack alignItems="center">
                <Link href="/" passHref>
                  <a aria-label="We Feed Raw">
                    <Image src={logo} alt="" width={138} height={16} />
                  </a>
                </Link>

                <CloseButton>
                  <Icon color="accent-black" name="close-line" />
                  <VisuallyHidden>{t('common:close')}</VisuallyHidden>
                </CloseButton>
              </Stack>

              <Stack
                alignItems="center"
                gap="3"
                display={{ '@initial': 'none', '@mobile': 'flex' }}
              >
                <MyAccountLink
                  isMobileScreenMenu
                  isLogged={isLogged}
                  isMyAccountMenuOpen={isMyAccountMobileMenuOpen}
                  setIsMyAccountMenuOpen={setIsMyAccountMobileMenuOpen}
                  setShowLogoutConfirmationModal={setShowLogoutConfirmationModal}
                />

                <ShoppingCartLink />
              </Stack>
            </Stack>
          </div>

          <Stack direction="column" justifyContent="space-between" css={{ height: '100%' }}>
            <SectionsNav>
              <Stack as="ul" direction="column" gap="3">
                {SECTIONS_NAV.map(({ label, href }) => (
                  <li key={href}>
                    {href === pathname ? (
                      <CloseButton>
                        <Text
                          as="a"
                          color="accent-black"
                          aria-label={label}
                          title={label}
                          weight="regular"
                        >
                          {label}
                        </Text>
                      </CloseButton>
                    ) : (
                      <Link href={href} passHref>
                        <Text
                          as="a"
                          color="accent-black"
                          aria-label={label}
                          title={label}
                          weight="regular"
                        >
                          {label}
                        </Text>
                      </Link>
                    )}
                  </li>
                ))}
              </Stack>
            </SectionsNav>

            <SocialNav>
              <Stack as="ul" justifyContent="space-between">
                {SOCIAL_NAV.map(({ icon, label, href }) => (
                  <li key={href}>
                    <a href={href} aria-label={label} target="_blank" rel="noreferrer">
                      <Icon color="accent-black" name={icon} />
                    </a>
                  </li>
                ))}
              </Stack>
            </SocialNav>
          </Stack>
        </Stack>
      </Content>
    </Root>
  )
}

MobileHeaderMenu.displayName = 'MobileHeaderMenu'
