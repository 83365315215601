import { buttonReset } from 'components/Button/Button.styles'
import { styled } from 'stitches.config'

export const LayoutRoot = styled('div', {
  margin: 'auto',
  width: '100%',
  position: 'relative',

  '.layout-cta': {
    cursor: 'pointer',
    position: 'fixed',
    bottom: '$3',
    right: '50%',
    transform: 'translateX(50%)',
  },
})

export const ButtonLabel = styled('span', {
  bottom: '$12',
  position: 'fixed',
  right: '50%',
  transform: 'translateX(50%)',
  width: '100%',
  fontSize: '$body',
  color: '$accent-black',
  textAlign: 'center',
  backgroundColor: '$tertiary-200', 
  opacity: '0.95',
  padding: '$2 $2 0',
})

export const Wrapper = styled('div', {
  maxWidth: '$max',
  position: 'relative',
  width: '100%',
  margin: 'auto',
  zIndex: '$max',
})

export const ChatButtonContainer = styled('div', {
  position: 'absolute',
  bottom: '$space$9',
  right: '$space$9',
  zIndex: '$max',

  '@bp1': {
    right: '$space$11',
    bottom: '$space$10',
  },
})

export const ChatButton = styled('button', {
  ...buttonReset,
  cursor: 'pointer',
  position: 'fixed',
  bottom: '$space$9',
  size: '$8',
  backgroundColor: '$accent-white',
  borderRadius: '$round',
  boxShadow: '0px 6px 4px 0px rgba(0, 0, 0, 0.12)',
  transform: 'translateX(-10%)',

  '@bp1': {
    size: '$10',
    bottom: '$space$10',
  },
})

export const LinkContainer = styled('span', {
  '@bp2': {
    display: 'none',
  },
})

export const ToastContainer = styled('div', {
  position: 'fixed',
  top: '96px',
  right: 0,
  zIndex: '$max',
})

export const ButtonOverlay = styled('div', {
  width: '100%',
  backgroundColor: '$tertiary-200', 
  opacity: '0.95',
  padding: '$6 0',
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 0,

  '@bp2': {
    display: 'none', 
  },
});