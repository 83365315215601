import React, { ComponentProps, ReactElement } from 'react'
import { useTranslation } from 'hooks/useTranslation'
import { Link } from 'components/Link'
import { Button } from 'components/Button'
import { Toast } from 'components/Toast'
import { Stack } from 'components/Stack'
import { useToast } from 'contexts/ToastContext'
import { useShoppingCartSidebarProvider } from 'contexts/ShoppingCartSidebarContext'
import { ShoppingCartSidebar } from 'features/components/ShoppingCartSidebar'
import { TranslationKeys } from 'locales/translationKeys'
import { LayoutRoot, LinkContainer, Wrapper, ToastContainer, ButtonOverlay, ButtonLabel } from './Layout.styles'

type LayoutOwnProps = ComponentProps<typeof LayoutRoot>
export type LayoutCTA = {
  text: TranslationKeys
  url: string
}

export type LayoutProps = LayoutOwnProps & {
  header: ReactElement
  hideCTA?: boolean
  CTA?: LayoutCTA
  ctaLabel?: TranslationKeys
}

export const Layout = ({
  header,
  hideCTA = false,
  children,
  CTA = { text: 'common:layout.meal-plan', url: '/meal-plan' },
  ctaLabel
}: LayoutProps) => {
  const { t } = useTranslation()
  const { toasts } = useToast()
  const { showSidebar } = useShoppingCartSidebarProvider()

  return (
    <LayoutRoot>
      {header}
      {children}

      <Wrapper>
      {!hideCTA && <ButtonOverlay />}
        <LinkContainer>
          {ctaLabel && <ButtonLabel>{t(ctaLabel)}</ButtonLabel>}
          {!hideCTA && (
            <Link href={CTA.url} passHref>
              <Button as="a" className="layout-cta" css={{ zIndex: 1 }}>
                {t(CTA.text)}
              </Button>
            </Link> 
          )}
        </LinkContainer>
      </Wrapper>

      <ToastContainer>
        <Stack gap="2" direction="column">
          {toasts?.map((toast) => (
            <Toast key={toast.id} {...toast} />
          ))}
        </Stack>
      </ToastContainer>

      {showSidebar && <ShoppingCartSidebar />}
    </LayoutRoot>
  )
}

Layout.displayName = 'Layout'
