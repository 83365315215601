import { styled } from 'stitches.config'

export const SidebarPadding = styled('div', {
  padding: '$3',
  py: 0,
})

export const ShoppingCartSidebarRoot = styled('aside', {
  display: 'none',
  position: 'fixed',
  top: 0,
  right: -460,
  bottom: 0,
  zIndex: 1000,
  backgroundColor: '$accent-white',
  paddingTop: '$2',
  minWidth: '100%',
  transition: 'right ease-out 300ms, opacity ease-in 300ms',
  opacity: 0,
  boxShadow: '-3px 0px 13px 0 $colors$tertiary-100',

  '@bp2': {
    minWidth: '460px',
    display: 'initial',
  },

  variants: {
    isShown: {
      true: {
        right: 0,
        opacity: 1,
      },
    },
  },
})

export const LogoContainer = styled('div', {
  size: '$7',
})

export const Content = styled('div', {
  px: '$1',
})

export const Scrollable = styled('div', {
  overflow: 'auto',
  maxHeight: '60vh',
})

export const SidebarFooter = styled('footer', {
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  paddingBottom: '$3',
})
