import { ColorTokens, SizeTokens, styled, theme } from 'stitches.config'

const color = Object.keys(theme.colors).reduce((acc, color) => {
  return { ...acc, [color]: { '&': { backgroundColor: `$${color}` } } }
}, {}) as { [key in ColorTokens]: any }

const size = Object.keys(theme.sizes).reduce((acc, size) => {
  return { ...acc, [size]: { '&': { height: `$${size}` } } }
}, {}) as { [key in SizeTokens]: any }

export const VerticalSeparatorRoot = styled('div', {
  width: '1px',

  variants: {
    color,
    size,
  },
  defaultVariants: {
    color: 'tertiary-300',
    size: '12',
  },
})
