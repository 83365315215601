import React, { ComponentProps, ReactNode } from 'react'
import { Root, Trigger } from '@radix-ui/react-dialog'
import { Overlay, Content, CloseButton } from './Dialog.styles'
import { VisuallyHidden } from 'components/VisuallyHidden'
import { useTranslation } from 'hooks/useTranslation'
import { Icon } from 'components/Icon'

type DialogOwnProps = ComponentProps<typeof Content>
export type DialogProps = DialogOwnProps & {
  trigger?: ReactNode
  defaultOpen?: boolean
  open?: boolean
  onOpenChange?: (isOpen: boolean) => void
}

export const Dialog = ({
  children,
  open,
  trigger,
  onOpenChange,
  defaultOpen = false,
  ...props
}: DialogProps) => {
  const { t } = useTranslation()
  return (
    <Root defaultOpen={defaultOpen} open={open} onOpenChange={onOpenChange}>
      {trigger && <Trigger asChild>{trigger}</Trigger>}
      <Overlay />
      <Content {...props}>
        <CloseButton>
          <Icon color="accent-black" name="close-line" />
          <VisuallyHidden>{t('common:close')}</VisuallyHidden>
        </CloseButton>
        {children}
      </Content>
    </Root>
  )
}

Dialog.displayName = 'Dialog'
