import { styled } from 'stitches.config'

export const buttonReset = {
  border: 'none',
  margin: '0',
  padding: '0',
  width: 'auto',
  overflow: 'visible',

  background: 'transparent',
  color: 'inherit',
  font: 'inherit',
  lineHeight: 'normal',
  '-webkit-font-smoothing': 'inherit',
  '-moz-osx-font-smoothing': 'inherit',

  '-webkit-appearance': 'none',
}

export const ButtonRoot = styled('button', {
  ...buttonReset,
  cursor: 'pointer',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  py: '$2',
  px: '$4',
  color: '$accent-white',
  fontSize: '$body',
  height: '$$size',
  borderRadius: '4px',
  backgroundColor: '$primary-500',
  fontWeight: '$regular',
  opacity: 1,
  transition: 'all 0.3s ease',

  '&:disabled': {
    cursor: 'not-allowed',
    opacity: 0.65,
  },

  variants: {
    size: {
      tiny: {
        $$size: '$sizes$4',
        size: '$$size',
        py: '$1',
      },
      small: {
        $$size: '$sizes$5',
        py: '$1',
      },
      regular: {
        $$size: '$sizes$7',
        fontSize: 'body',
      },
    },
    onlyIcon: {
      true: {
        py: 0,
        px: 0,
        minWidth: 0,
        borderRadius: '$2',
        border: '1px solid',

        '&': {
          size: '$$size',
        },
      },
    },
    variant: {
      primary: {
        backgroundColor: '$primary-500',
        '&:hover': {
          backgroundColor: '$primary-400',
        },
        '&:active': {
          backgroundColor: '$primary-600',
        },
        '&:disabled': {
          backgroundColor: '$primary-400',
        },
      },
      secondary: {
        backgroundColor: '$secondary-500',
        '&:hover': {
          backgroundColor: '$secondary-400',
        },
        '&:active': {
          backgroundColor: '$secondary-600',
        },
        '&:disabled': {
          backgroundColor: '$secondary-500',
        },
      },
      secondaryAlt: {
        color: '$primary-500',
        backgroundColor: '$accent-white',
        border: '2px solid $primary-500',
        '&:hover': {
          backgroundColor: '$primary-500',
          color: '$accent-white',
          border: '2px solid $primary-500',
        },
        '&:active': {
          backgroundColor: '$primary-600',
          color: '$accent-white',
          border: '2px solid $primary-600',
        },
        '&:disabled': {
          backgroundColor: '$accent-white',
          color: '$primary-500',
          border: '2px solid $primary-500',
        },
      },
      tertiary: {
        color: '$secondary-500',
        backgroundColor: '$accent-white',
        border: '2px solid $secondary-500',
        '&:hover': {
          color: '$secondary-400',
          border: '2px solid $secondary-400',
        },
        '&:active': {
          color: '$secondary-600',
          border: '2px solid $secondary-600',
        },
        '&:disabled': {
          color: '$secondary-500',
          border: '2px solid $secondary-500',
          opacity: 1,
        },
      },
      underline: {
        color: '$accent-black',
        textDecoration: 'underline',
        backgroundColor: 'transparent',
        border: 'none',
        padding: '0',
        '&:hover': {
          color: '$accent-black',
          textDecoration: 'none',
        },
      },
    },
    fullWidth: {
      true: {
        width: '100%',
      }
    }
  },

  defaultVariants: {
    variant: 'primary',
    size: 'regular',
  },
})
