import { buttonReset } from 'components/Button/Button.styles'
import { styled, keyframes } from 'stitches.config'

export const Isotype = styled('img', {
 display: 'block',
  '@bp2': {
    size: '$5',
  },
})

export const Logo = styled('div', {
  display: 'flex',
  justifyContent: 'center',

  width: '137px',
  height: '26px',

  '@bp2': {
    width: '206px',
    height: '24px',
  },

  variants: {
    hidden: {
      true: {
        display: 'none',
        '@bp2': {
          display: 'none',
        }
      },
    },
  },
})

export const MobileMenuButton = styled('button', {
  cursor: 'pointer',
  backgroundColor: 'transparent',
  border: 'none',

  display: 'flex',
})

export const MainNav = styled('nav', {
  display: 'flex',
  flex: '1',
  justifyContent: 'flex-end',

  '& ul': {
    whiteSpace: 'nowrap',
    lineHeight: '$body',
    gap: '$3',
    display: 'flex',
    alignItems: 'center',
    fontFamily: '$body',

    '@bp3': {
      gap: '$5',
    },

  },
})

export const MainNavItem = styled('li', {
  py: '$1',

  '& span': {
    color: '$accent-black',
    fontFamily: '$body',
  },

  '& a': {
    padding: '$1 $2',
    alignItems: 'center',
    borderRadius: '$3',
    color: '$accent-black',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '$accent-white',
    },

    '&:active': {
      backgroundColor: '$accent-white',
    },
  },
  variants: {
    active: {
      true: {
        '& a': {
          backgroundColor: '$accent-white',
        },
      },
    },
  },
})

export const UserSectionNav = styled('nav', {
  '& ul': {
    position: 'relative',
    height: '$3',
  },

  '& a': {
    fontFamily: '$body',
  },
})

export const UserSectionNavItem = styled('li', {
  size: '$6',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$round',

  '&:hover': {
    cursor: 'pointer',
    color: '$primary-700',
    backgroundColor: '$accent-white',
  },

  '&:active': {
    color: '$tertiary-400',
    backgroundColor: '$accent-white',
  },

  variants: {
    active: {
      true: {
        color: '$tertiary-400',
        backgroundColor: '$accent-white',
      },
    },
  },
})

const fadeIn = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 }
});

export const HeaderContent = styled('div', {
  position: 'relative',
  height: '100%',
  maxWidth: '$max',
  width: '100%',
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  px: '$3',
  gap: '$1',
  overflow: 'hidden',
  animation: `${fadeIn} 0.7s ease-in-out forwards`,

  '@bp2': {
   gap: '$5',
   px: '$8',
  },

  '& svg': {
    size: '$3',
  },
})

export const HeaderRoot = styled('header', {
  display: 'flex',
  justifyContent: 'center',
  height: '$8',
  position: 'sticky',
  top: 0,
  left: 0,
  right: 0,
  color: '$accent-black',
  backgroundColor: '$accent-white',
  transition: 'all 150ms linear',
  zIndex: '$max',

  '@bp2': {
    height: '$9',
  },

  '.header-cta': {
    whiteSpace: 'nowrap',
    '@bp2': {
      display: 'inline-block',
    },
  },

  variants: {
    isInTop: {
      true: {
        '@bp2': {
          position: 'initial',
          top: 'auto',
          backgroundColor: 'transparent',
        },
      },
    },
    isFixed: {
      true: {
        '@bp2': {
          position: 'fixed',
          backgroundColor: '$tertiary-200',
        },
      },
    },
    snapToTop: {
      false: {
        borderBottom: '1px solid $tertiary-300',
      },
    },
  },
})

export const PopoverTrigger = styled('button', {
  ...buttonReset,
  cursor: 'pointer',
  borderRadius: '$round',
  size: '$6',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  variants: {
    menuActive: {
      true: {
        backgroundColor: '$accent-white',
      },
    },
    isMobileScreenMenu: {
      true: {
        backgroundColor: 'transparent',
      },
    },
    active: {
      true: {
      color: '$primary-700',
      backgroundColor: '$accent-white',
    }},
    isNavItem: {
      true: {
        size: '$5',
        padding: '$1 $8',
        alignItems: 'center',
        borderRadius: '$3',
        '&:hover': {
          cursor: 'pointer',
          color: '$primary-700',
          backgroundColor: '$accent-white',
        },

        '&:active': {
          color: '$tertiary-400',
          backgroundColor: '$accent-white',
        },
      },
    },
  },

  compoundVariants: [
    {
      menuActive: true,
      isMobileScreenMenu: true,
      css: {
        backgroundColor: '$accent-white',
      },
    },
  ],
})

export const PopoverList = styled('div', {
  minWidth: '216px',
  backgroundColor: '$accent-white',
  padding: '$3 $2',
  borderRadius: '$4',
  boxShadow: '2px 4px 8px rgba(0, 0, 0, 0.07)',

  '& span': {
    fontFamily: '$body',
  },

  '&': {
    [`${PopoverTrigger}`]: {
      size: 'auto',
      borderRadius: 0,
      width: '100%',
      justifyContent: 'flex-start',
    },
  },
})

export const PopoverItem = styled('li', {
  px: '$2',
  py: '$1',
  color: '$accent-black',

  '&:hover': {
    borderRadius: '$3',
    backgroundColor: '$accent-white',
    color: '$tertiary-400',

    '& span': {
      fontWeight: '$semiBold',
    },
  },

  variants: {
    active: {
      true: {
        borderRadius: '$3',
        color: '$primary-700',
        backgroundColor: '$accent-white',
      },
    },
  },
})

export const DialogWrapper = styled('div', {
  minWidth: '240px',

  '@bp1': {
    minWidth: '440px',
  },

  '@bp2': {
    minWidth: '492px',
  },
})

export const IconWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& svg': {
    transition: 'transform 0.3s ease',
  },

  '& .rotate': {
    transform: 'rotate(180deg)',
  }

})